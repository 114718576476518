<template>
  <v-app>
    <Navbar/>
    <v-main className="ma-4">
      <router-view></router-view>
    </v-main>

    <Footer/>
  </v-app>

</template>

<script>
import {mapState, mapActions} from 'vuex'
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'

export default {
  name: 'App',

  components: {
    Navbar,
    Footer
  },
  computed: {
    ...mapState({
      alert: state => state.alert
    })
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear'
    })
  },
  data: () => ({
    Token: false,
  }),
  watch: {
    $route() {
      this.clearAlert();
    }
  }
};
</script>
