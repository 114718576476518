<template>
  <v-row>
    <v-col cols="6">
      <v-card>
        <v-card-title>
          <h1>{{ title }}</h1>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="url" label="APKM URL" clearable />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="downloadAPKM" :disabled="!url || loading" :loading="loading" color="primary">
            Download
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card v-if="auto_status">
        <v-card-title>Status: {{ auto_completed ? 'Completed' : 'Downloading...' }}</v-card-title>
        <v-card-text v-text="auto_status_message" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {http} from '../helpers/http';
import notice from '../helpers/notice';
export default {
  name: 'DownloadAPKM',
  data() {
    return {
      title: 'Download APKM',
      url: "",
      loading: false,
      auto_status: null,
      auto_completed: false,
      auto_status_message: "...",
    }
  },
  methods: {
    fetchStatus(status_code){
      http.get(`/api/v1/apk/obb/status?redis_key=${status_code}`).then((res)=>{
          const json = res.data;
          if(json.message){
            this.auto_status_message = json.message;
            if(json.message.includes("\nEND")){
              clearInterval(this.auto_status);
              this.auto_completed = true;
            }
        }
      })
    },
    downloadAPKM() {
      this.loading = true;
      http.get(`/api/v1/apk/obb?url=${this.url}`).finally(()=>{
        this.loading = false;
      }).then((res)=>{
        const json = res.data;
        if(json.success){
          notice.success({message: json.message || "Downloading..."});
          if(json.data){
              this.auto_status = setInterval(()=>{
                this.fetchStatus(json.data);
              }, 1000);
            }else{
              notice.error({message: "Not found session key"});
            }
        }else{
          notice.error({message: json.message || "Unknown Error"});
        }
      }).catch((e)=>{
        notice.error({message: e.error});
      })
    }
  },
  beforeDestroy() {
    if(this.auto_status){
      this.auto_completed = false;
      clearInterval(this.auto_status);
    }
  },
}
</script>

<style scoped>
h1 {
  color: #42b983;
}
button {
  padding: 10px;
  background-color: #35495e;
  color: white;
  border: none;
  cursor: pointer;
}
</style>
