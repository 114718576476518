<template>
   <nav>
       <v-app-bar  color="blue" dark app >
           <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
           <v-toolbar-title class="text-uppercase ">
               <span class="font-weight-light">MOKKA</span>
               <span>NOOB</span>
           </v-toolbar-title>
						<template v-if="status.loggedIn">
							<v-divider
							class="mx-4"
							inset
							vertical
							/>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										dark
										text
										v-bind="attrs"
										v-on="on"
                    class="mx-2"
                    to="/online"
									>
										<v-icon left>
											mdi-account-convert
										</v-icon>
										Online: {{online.total}}
									</v-btn>
								</template>
								<div class="mx-2 text-center yellow--text">VIP KEY: <strong>{{online.vkey}}</strong></div>
								<div class="mx-2 text-center green--text">PUB KEY: <strong>{{online.pkey}}</strong></div>
                <div class="mx-2 text-center orange--text">IN GAME: <strong>{{online.ingame}}</strong></div>
                <div class="mx-2 text-center red--text">LOBBY: <strong>{{online.lobby}}</strong></div>
							</v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      dark
                      text
                      v-bind="attrs"
                      v-on="on"
                      class="mx-2"
                  >
                    <v-icon left>
                      mdi-resistor
                    </v-icon>
                    SYSTEM ({{system_stats.cpu}} - {{system_stats.memory.usage}} - {{system_stats.disk.usage}}) 
                    <!-- - {{ping}} ms -->
                  </v-btn>
                </template>
                <div class="mx-2 text-center orange--text"><img src="/images/cpu.svg" height="16px" width="16px" /> <strong>{{system_stats.cpu}}</strong></div>
                <div class="mx-2 text-center green--text"><img src="/images/ram.svg" height="16px" width="16px" /> 
                  <strong>Total: {{system_stats.memory.total}} | Used: {{system_stats.memory.used}} | Free: {{system_stats.memory.free}} ({{system_stats.memory.usage}})</strong>
                </div>
                <div class="mx-2 text-center green--text"><img src="/images/disk.svg" height="16px" width="16px" /> 
                  <strong>Total: {{system_stats.disk.total}} | Used: {{system_stats.disk.used}} | Free: {{system_stats.disk.free}} ({{system_stats.disk.usage}})</strong>
                </div>
              </v-tooltip>
              <v-btn
                  dark
                  text
                  class="mx-2"
                  to="/public-key?system=pending"
              >
                <v-icon left>
                  mdi-laptop-account
                </v-icon>
                Request HWID: {{ requests.total }}
              </v-btn>
              <v-btn
                  dark
                  text
                  class="mx-2"
                  @click="doKickAll"
              >
                <v-icon left>
                  mdi-power
                </v-icon>
                KICK ALL
              </v-btn>
						</template>
           <v-spacer></v-spacer>
            <v-btn text v-if="status.loggedIn" @click="Logout()">
                <span>Logout</span>
                <v-icon right>exit_to_app</v-icon>
             </v-btn>
       </v-app-bar>
      <v-navigation-drawer v-model="drawer" dark app class="blue darken-3" v-if="status.loggedIn">
          <v-list>
              <v-list-item @click="boxMenu = true">
                  <v-list-item-action>
                      <v-icon>mdi-cog</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title>Menu Setting</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="link in menuActive" :key="link.text" route :to="link.route" active-class="border">
                  <v-list-item-action>
                      <v-icon >{{link.icon}}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                      <v-list-item-title >{{link.text}}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item>
                  <v-list-item-content >
                      <v-divider />
                  </v-list-item-content>
              </v-list-item>
              <v-list-item>
                  <v-list-item-action>
                     <v-icon>mdi-chart-gantt</v-icon>
                  </v-list-item-action>
                  <v-list-item-content >
                      <v-list-item-title>STATS</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          color="orange"
                          class="ma-2 white--text"
                          small
                          text
                        >
                          TOTAL SELLER: {{cached.total_sellers ? cached.total_sellers:'...'}}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          color="orange lighten-1"
                          class="ma-2 white--text"
                          small
                          text
                        >
                          TOTAL KEYS: {{cached.total_keys ? cached.total_keys:'...'}}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          color="orange lighten-2"
                          class="ma-2 white--text"
                          small
                          text
                        >
                          UNUSED KEYS: {{cached.total_unused_keys ? cached.total_unused_keys+`(${cached.total_unused_testkeys})`:'...'}} 
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          color="orange lighten-3"
                          class="ma-2 white--text"
                          small
                          text
                        >
                          USED KEYS: {{cached.total_used_keys ? cached.total_used_keys:'...'}}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-btn
                          color="orange lighten-4"
                          class="ma-2 white--text"
                          small
                          text
                        >
                          EXPIRED KEYS: {{cached.total_expired_keys ? cached.total_expired_keys:'...'}}
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-list-item-content>
              </v-list-item>
          </v-list>
<!--          <v-list-group-->
<!--              :value="false"-->
<!--              prepend-icon="mdi-eye-off"-->
<!--          >-->
<!--              <template v-slot:activator>-->
<!--                  <v-list-item-title>Menu Inactive</v-list-item-title>-->
<!--              </template>-->
<!--              <v-list-item v-for="link in menuHidden" :key="link.text" active-class="border">-->
<!--                  <v-list-item-action>-->
<!--                      <v-icon >{{link.icon}}</v-icon>-->
<!--                  </v-list-item-action>-->
<!--                  <v-list-item-content>-->
<!--                      <v-list-item-title >{{link.text}}</v-list-item-title>-->
<!--                  </v-list-item-content>-->
<!--              </v-list-item>-->
<!--          </v-list-group>-->
      </v-navigation-drawer>
       <v-dialog
            v-model="boxMenu"
            max-width="290"
       >
           <v-card dark>
                <v-card-title>
                    MENU SHOW/HIDE
                </v-card-title>
               <v-card-text>
                    <v-list>
                        <v-list-item-group>
                            <v-list-item v-for="link in links" :key="link.text">
                                <v-list-item-content>
                                    <v-list-item-title>{{link.text}}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-checkbox
                                        :input-value="hidden_menu.includes(link.route)"
                                        @change="toggleHideMenu(link.route)"
                                    />
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
               </v-card-text>
               <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        @click="boxMenu = false"
                    >
                        Close
                    </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
   </nav>
</template>
<script>
import io from 'socket.io-client';
import { fetchData } from '../helpers';
import { mapState,mapActions } from 'vuex'
import Alert from '../helpers/notice';
export default {
   data: () => ({
      itemActive: true,
      boxMenu: false,
      ping: '...',
      cached: {},
      auto: undefined,
      auto_system: undefined,
      auto_ping: undefined,
      system_resource: {
        cpu: '...',
        memory: '...',
        free: '...',
        used: '...',
        disk: {
          total: '...',
          available: '...',
          used: '...',
        }
      },
      system_stats: {
        cpu: '...',
        memory: {
          total: '...',
          free: '...',
          used: '...',
          usage: '...',
        },
        disk: {
          total: '...',
          free: '...',
          used: '...',
          usage: '...',
        }
      },
      hidden_menu: [],
			online: {online:'...'},
      requests: {total:'...'},
      drawer: true,
      links :[
          {icon: 'mdi-account-multiple', text:'Seller Manager', route: '/'},
          {icon: 'mdi-box-shadow', text:'Plans', route: '/plans'},
          {icon: 'mdi-pistol', text:'Online', route: '/online'},
          {icon: 'mdi-order-alphabetical-ascending', text:'Transaction', route: '/transaction'},
          {icon: 'mdi-account-key', text:'Vip Key', route: '/vip-key'},
          {icon: 'mdi-account-key', text:'Discount via Country', route: '/discount/country'},
          {icon: 'mdi-account-key', text:'Expired Key', route: '/deleted-key'},
          {icon: 'mdi-shield-key', text:'Public Key', route: '/public-key'},
          {icon: 'mdi-keyboard-off', text:'Blacklist', route: '/blacklist'},
          {icon: 'mdi-cog', text:'System Manager', route: '/system'},
          //{icon: 'mdi-basket', text:'Shoppy.GG', route: '/shoppy'},
          {icon: 'mdi-air-conditioner', text:'Cheat Value', route: '/cheats'},
										{icon: 'mdi-application-brackets', text:'Allowed Items', route: '/apps'},
          {icon: 'mdi-cellphone-arrow-down', text:'Update', route: '/updates'},
          {icon: 'mdi-math-log', text:'Logs', route: '/logs'},
          {icon: 'mdi-math-log', text:'Audit Logs', route: '/audit-logs'},
          {icon: 'mdi-wall-fire', text:'Firewall', route: '/firewall'},
          {icon: 'mdi-folder-marker', text:'File Manager', route: '/file-manager'},
          {icon: 'mdi-web', text:'Web Radar', route: '/webradar'},
      ],
    }),
  components: {
  },
  computed: {
      ...mapState('auth', ['status']),
      disk_usage(){
        const {used, total} = this.system_resource.disk;
        if(used === '...' || total === '...'){
          return '...'
        }else{
          return `${((used*1*100)/(total*1)).toFixed(2)}%`
        }
      },
      menuHidden(){
          return this.links.filter(link => this.hidden_menu.includes(link.route));
      },
      menuActive(){
          return this.links.filter(link => !this.hidden_menu.includes(link.route));
      }
    },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    doLoadMenuLocal(){
      const menu = localStorage.getItem('hidden_menu');
      let menuJSON = null;
      try{
        menuJSON = JSON.parse(menu);
      }catch (e) {
          console.error(e);
      }
      if(menuJSON) this.doUpdateMenuSystem(menuJSON);
    },
    doUpdateMenuSystem(menu){
        this.hidden_menu = JSON.parse(JSON.stringify(menu));
    },
    doSaveMenuSystem(){
      localStorage.setItem('hidden_menu',JSON.stringify(this.hidden_menu));
      // fetchData(`${this.$API}/api/update-menu-system`,{menu: this.hidden_menu});
    },
    toggleMenuSetting(e){
        this.boxMenu = true;
        e.preventDefault();
    },
    toggleHideMenu(menu){
        if(this.hidden_menu.includes(menu))
            this.hidden_menu.splice(this.hidden_menu.indexOf(menu),1);
        else
            this.hidden_menu.push(menu);
        this.doSaveMenuSystem();
    },
    Logout () {
      this.logout();
    },
    FetchData(){
      // let list = [this.FetchOnline(), this.FetchRequests()];
      // return Promise.all(list);
      return this.FetchRequests();
    },
    doKickAll(){
      if(this.status.loggedIn){
        return Alert.confirm({title:"Are you sure ? ",message:"This action will kick all online players"}).then(result=> {
          if(result) {
            return fetchData(`${this.$API}/api/kick-all`).then((json) => {
              if (json.success) {
                Alert.success({message: "KICKED ALL USER ONLINE !!!"});
              }
              if (json.error) {
                Alert.error({message: json.error});
              }
            }, (err) => {
              if (err === 401) {
                this.logout();
              } else {
                this.error('SERVER ERROR !!!');
              }
            });
          }
        });
      }
    },
    FetchRequests(){
      if(this.status.loggedIn){
        return fetchData(`${this.$API}/api/requests`).then((json)=>{
          this.requests = JSON.parse(json);
        },(err)=>{
          if(err === 401){
            this.logout();
          }else{
            this.error('SERVER ERROR !!!');
          }
        });
      }
    },
		FetchOnline(){
			if(this.status.loggedIn){
				return fetchData(`${this.$API}/api/online`).then((json)=>{
					this.online = json;
				},(err)=>{
					if(err === 401){
						this.logout();
					}else{
						this.error('SERVER ERROR !!!');
					}
				});
			}
		},
    FetchSystemResource(){
      if(this.status.loggedIn){
        // return fetchData(`${this.$API}/api/system-resource`).then((json)=>{
        //   this.system_resource = json;
        // },(err)=>{
        //   if(err === 401){
        //     this.logout();
        //   }else{
        //     this.error('SERVER ERROR !!!');
        //   }
        // });
      }
    },
    parseSystemSSE(e){
        // console.log(e);
        if(e.data){
          const data = e.data.split("::");
          const memory = data[0].split("|");
          const disk = data[1].split("|");
          const cpu = data[2];
          this.system_stats =  {
            cpu,
            memory: {
              total: memory[0],
              free: memory[1],
              used: memory[2],
              usage: memory[3],
            },
            disk: {
              total: disk[0],
              free: disk[1],
              used: disk[2],
              usage: disk[3],
            }
          };
        }
    },
    parseOwnerSSE(e){
        if(e.data){
          try{
            this.cached = JSON.parse(e.data);
          }catch(e){
            console.error(e);
          }
        }
    },
    parseOnlineSSE(e){
        if(e.data){
          try{
            this.online = JSON.parse(e.data);
          }catch(e){
            console.error(e);
          }
        }
    }
  },
	mounted(){
    const eventSource = new EventSource(`${this.$API}/sse/system-info`);
    eventSource.addEventListener("system-stats", this.parseSystemSSE);
    eventSource.addEventListener("owner-stats", this.parseOwnerSSE);
    eventSource.addEventListener("online-stats", this.parseOnlineSSE);
    this.doLoadMenuLocal();
		this.FetchData();
    // this.FetchSystemResource();
		this.auto = setInterval(this.FetchData,30000);
    // this.auto_system = setInterval(this.FetchSystemResource,30000);
    // const socket = io(`${this.$API}`);
    // let startTime;
    // const app = this;
    // socket.on('connect', () => {
    //   app.auto_ping = setInterval(() => {
    //     startTime = Date.now();
    //     socket.emit('ping', startTime);
    //   }, 1000);
    // });

    // socket.on('pong', (timestamp) => {
    //   const latency = Date.now() - timestamp;
    //   app.ping = latency;
    // });
    // socket.on('system-resources', (data) => {
    //   app.system_resource = data;
    // });
    // socket.on('onlines', (data) => {
    //   app.online = data;
    // });
    // socket.on('owner-stats-cached', (data) => {
    //   app.cached = data;
    // });
	},
	beforeDestroy(){
		clearInterval(this.auto);
    // clearInterval(this.auto_system);
    // clearInterval(this.auto_ping);
		this.auto = undefined;
    this.auto_system = undefined;
    this.auto_ping = undefined;
	}
}
</script>
<style scoped>
.border {
  border-left: 4px solid #2196f3;
}
</style>
