export default {
    Save(key, data){
        if(typeof data === 'object' && data !== null ) {
            data = JSON.stringify(data);
        }
        localStorage.setItem(key, data);
    },
    Load(key, defaultValue = null){
        const data = localStorage.getItem(key);
        if(data){
            try {
                return JSON.parse(data);
            } catch (error) {
                return data;
            }
        }
        return defaultValue;
    }
}