export * from './auth-header';
export * from './fetchData';
const doParseTime = function(data){
    var dt = new Date(data*1000);
    return dt.toLocaleString();
};
const parseSize = function(size){
    if(size > 1024){
        const kb = size/1024;
        if (kb > 1024) {
            const mb = kb/1024;
            if (mb > 1024) {
                const gb = Math.floor(mb/1024);
                return `${gb.toFixed(2)} GB`;
            }
            return `${mb.toFixed(2)} MB`;
        }
        return `${kb.toFixed(2)} KB`;
    }
    return `${size} bytes`;
}

export {
    doParseTime,
    parseSize,
};